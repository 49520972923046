<template>
  <div id="info">
    <el-descriptions title="用户信息">
      <el-descriptions-item label="用户名">{{
        authInfo.userName
      }}</el-descriptions-item>
      <el-descriptions-item label="昵称">{{
        authInfo.nickName
      }}</el-descriptions-item>
      <el-descriptions-item label="职务">{{
        authInfo.auTypeDesc[authInfo.auType]
      }}</el-descriptions-item>
      <el-descriptions-item label="登陆ip">{{
        authInfo.loginIp
      }}</el-descriptions-item>
    </el-descriptions>
  </div>
</template>

<script>
export default {
  name: "info",
  data: function () {
    return {};
  },

  methods: {},

  created() {},

  computed: {
    authInfo: function () {
      return this.$store.state.auth.authInfo;
    },
  },
};
</script>

<style scoped>
</style>